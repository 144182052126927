@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    --border: 216 34% 17%;
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #d8d9db inset !important;
    -webkit-text-fill-color: #5a5a5a !important;
  }

  input::selection {
    background: #d8d9db;
    color: #5a5a5a;
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
    font-feature-settings:
      "rlig" 1,
      "calt" 1;
  }
}

@layer components {
  .btn {
    @apply w-full flex items-center justify-center py-2 rounded-lg bg-Button text-extralight hover:cursor-pointer hover:bg-ButtonHover;
  }

  .input-container {
    @apply flex items-center border border-extralight p-2 md:p-3 rounded-lg mt-1 bg-extralight;
  }

  .input-field {
    @apply w-full ml-2 outline-none bg-transparent placeholder:text-placeholder placeholder:text-subparagraph md:placeholder:text-subparagraph placeholder:font-semibold placeholder:leading-5;
  }

  .error-message {
    @apply text-red-400 text-subparagraph font-light;
  }
}


@layer components {
  .partner-grid {
    overflow: hidden;
    white-space: nowrap;
  }

  .slide-track {
    display: flex;
    animation: slide 20s linear infinite;
  }

  @keyframes slide {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-120%);
    }
  }

  .slide-track a {
    flex: 0 0 auto;
  }
}
